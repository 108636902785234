<template>
  <div>
    <form class="row" @submit.prevent="save()">
      <div class="col-lg-4 col-md-6 col-12">
        <InfoBlock
            @onInputHandler = "onInputHandler"
        />
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <DocumentBlock
            @onInputHandler = "onInputHandler"
        />
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <CharacteristicBlock
            @onInputHandler = "onInputHandler"
        />
      </div>
      <div class="container p-5">Стоимость доставки: {{this.cost}} тг</div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cost: 0,
      carriage: {
        phone: '+7',
        fio: '',
        date: null,
        time: null,
        from: 'Город',
        from_address: null,
        volume: 0.1,
        weight: 0,
        to_fio: null,
        to_phone: '+7',
        address: null,
        to: 'Город',
        lat: null,
        long: null,
        from_lat: null,
        from_long: null,
        type: 'there',
        product_name: null
      }
    }
  },
  components: {
    InfoBlock: () => ({
      component: import("@/components/add/carriage/info"),
    }),
    DocumentBlock: () => ({
      component: import("@/components/add/carriage/doc"),
    }),
    CharacteristicBlock: () => ({
      component: import("@/components/add/carriage/characteristic"),
    }),
  },
  methods: {
    onInputHandler(e) {
      this.carriage[e.key] = e.param
    },
    async calc() {
      const formData = new FormData();
      formData.set("lat", this.carriage.lat);
      formData.set("long", this.carriage.long);
      formData.set("from_lat", this.carriage.from_lat);
      formData.set("from_long", this.carriage.from_long);

      const url = "/web/calc";
      await this.$api
          .post(url, formData)
          .then((response) => {
            this.cost = response.data.total
            this.$toast.success("Стоимость доставки "+this.cost+ "тг.");
          })
          .catch(() => {
            this.$toast.error('Что-то пошло не так, попробуйте позднее!');
          });
    },
    async save() {
      this.calc()
      if (this.cost !== 0) {
        const formData = new FormData();
        formData.set("phone", this.carriage.phone);
        formData.set("fio", this.carriage.fio);
        formData.set("date", this.carriage.date);
        formData.set("time", this.carriage.time);
        formData.set("from", this.carriage.from);
        formData.set("from_address", this.carriage.from_address);
        formData.set("volume", this.carriage.volume);
        formData.set("weight", this.carriage.weight);
        formData.set("to_fio", this.carriage.to_fio);
        formData.set("to_phone", this.carriage.to_phone);
        formData.set("address", this.carriage.address);
        formData.set("to", this.carriage.to);
        formData.set("lat", this.carriage.lat);
        formData.set("long", this.carriage.long);
        formData.set("type", this.carriage.type);
        formData.set("from_long", this.carriage.from_long);
        formData.set("from_lat", this.carriage.from_lat);
        formData.set("product_name", this.carriage.product_name);

        const url = "/web/point-store";
        await this.$api
            .post(url, formData)
            .then(() => {
              this.$toast.success("Успешно!");
            })
            .catch(() => {
              this.$toast.error('Что-то пошло не так, попробуйте позднее!');
            });
      }
    },
  },
};
</script>

<style scoped></style>
